.newyear-rule-container {
    position: absolute;
    left: 50%;
    top: 50%;
    width: 78vw;
    height: 186.667vw;
    background-image: url(./asset/rule-bg.png);
    background-size: 100% 100%;
    transform: translate3d(-50%, -50%, 0);
}

.newyear-rule-close {
    position: absolute;
    width: 9.067vw;
    height: 9.067vw;
    background-image: url(./asset/rule-close.png);
    background-size: 100% 100%;
    top: 3.133vw;
    right: 5.8vw;
}

.newyear-prize-container {
    position: absolute;
    left: 50%;
    top: 50%;
    width: 78vw;
    height: 104.267vw;
    background-image: url(./asset/dialog.png);
    background-size: 100% 100%;
    transform: translate3d(-50%, -50%, 0);
    padding-top: 30.8vw;
}

.newyear-prize-close {
    position: absolute;
    width: 11.067vw;
    height: 11.067vw;
    background-image: url(./asset/prize-close.png);
    background-size: 100% 100%;
    bottom: -21.6vw;
    left: 33.467vw;
}

.newyear-prize-box {
    width: 35.733vw;
    height: 35.733vw;
    margin: 0 auto;
    border-radius: 4vw;
    background-color: #ffc7c7;
    padding-top: 4.533vw;
}

.newyear-prize-dialog-line {
    height: 26.533vw;
    font-size: 0;
    text-align: center;
}

.newyear-prize-name {
    height: 4.8vw;
    margin-top: 3.067vw;
    font-size: 5.067vw;
    line-height: 4.8vw;
    color: #000200;
    text-align: center;
}

.newyear-prize-btn-line {
    height: 12vw;
    margin-top: 8.667vw;
    font-size: 0;
    text-align: center;
}

.newyear-prize-btn {
    height: 100%;
    width: 48vw;
    background-image: url(./asset/prize-btn.png);
    background-size: 100% 100%;
}