html,
body,
p,
ol,
ul,
li,
textarea,
iframe,
hr,
h1,
h2,
h3,
h4,
h5,
h6 {
  padding: 0;
  margin: 0
}

a {
  text-decoration: none;
  color: inherit
}

ul {
  list-style: none
}

button,
input,
select {
  margin: 0
}

html,
body,
div,
ul,
li {
  box-sizing: border-box
}

*,
*::before,
*::after {
  box-sizing: inherit
}

img,
video {
  border: none;
  height: auto;
  max-width: 100%
}

iframe {
  border: 0
}

button {
  border: 0;
  outline: 0;
  padding: 0;
  background-color: transparent;
  background-color: unset;
  cursor: pointer
}

input:focus,
textarea:focus {
  outline: 0
}

input {
  border: 0;
  padding: 0;
  background-color: transparent;
  background-color: unset;
  font-family: -apple-system, Microsoft Yahei, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif
}

textarea {
  border: 0;
  resize: none;
  font-family: -apple-system, Microsoft Yahei, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif;
}

html {
  font-size: 100px;
  min-height: 100%;
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.app-page-container {
  position: fixed;
  left: 0;
  top: 0;
  width: 100vw;
  height: 100vh;
  height: calc(var(--vh, 1vh)*100);
  background-color: #ffffff;
  overflow: hidden;
}

.app-page-container.dialog {
  background-color: rgba(0, 0, 0, 0.6);
}

.app-page-container.layout {
  background-color: unset;
}

.app-page-container.tip,
.app-page-container.tip-landscape {
  background-color: unset;
  pointer-events: none;
  z-index: 9;
}

.app-page-container.readonly {
  pointer-events: none;
}

.app-page-container.zIndex1 {
  z-index: 1;
}

.app-page-container.zIndex2 {
  z-index: 2;
}

.app-page-container.zIndex3 {
  z-index: 3;
}

.app-page-container.zIndex5 {
  z-index: 5;
}

.app-page-container.nest {
  position: absolute;
  width: 100%;
  height: 100%;
}

.app-page-container.pay {
  z-index: 5;
}

.app-page-container.pt44 {
  padding-top: 0.44rem;
}

.app-page-container.pt54 {
  padding-top: 0.54rem;
}

.app-page-container.pt56 {
  padding-top: 0.56rem;
}

.app-page-container.pt88 {
  padding-top: 0.88rem;
}

.app-page-header {
  position: absolute;
  width: 100%;
  left: 0;
  top: 0;
  height: 0.44rem;
}

.app-page-header-title {
  font-size: 0.16rem;
  line-height: 0.44rem;
  color: #061925;
  font-weight: bold;
  text-align: center;
}

.app-page-header-back,
.app-page-header-help {
  position: absolute;
  width: 0.24rem;
  height: 0.24rem;
  background-size: 100% 100%;
  top: 0.1rem;
}

.app-page-header-back {
  background-image: url(./assets/common/back-black.png);
  left: 0.16rem;
}

.app-page-header-help {
  background-image: url(./assets/accountHelper/help.png);
  right: 0.16rem;
}

.app-page-header-group {
  position: absolute;
  width: 0.85rem;
  height: 0.21rem;
  top: 0.12rem;
  right: 0.16rem;
  border-radius: 0.07rem;
  background-color: #f6f8fc;
  font-size: 0.11rem;
  line-height: 0.21rem;
  color: #121310;
  text-align: center;
}

.app-page-header-group.cdk {
  background-color: rgba(255, 255, 255, 0.6);
}

.app-page-header-group::before {
  display: inline-block;
  vertical-align: top;
  content: '';
  width: 0.12rem;
  height: 0.12rem;
  background-image: url(./assets/common/help.png);
  background-size: 100% 100%;
  margin-top: 0.05rem;
  margin-right: 0.02rem;
}

.app-page-window {
  height: 100%;
  overflow-x: hidden;
  overflow-y: auto;
  overscroll-behavior: none;
}

.app-page-window.grey {
  background-color: #f6f8fc;
}

.app-page-window::-webkit-scrollbar {
  display: none;
}

.app-page-content {
  height: 100%;
  padding: 0 0.16rem;
}

.app-whole-page {
  min-height: 100%;
  display: flex;
  flex-direction: column;
}

.app-popup-close {
  position: absolute;
  width: 0.32rem;
  height: 0.32rem;
  background-image: url(./assets/common/close.png);
  background-size: 100% 100%;
  left: 0.16rem;
  top: 0.5rem;
}

.app-popup-close.safe-area {
  top: 0.06rem;
}

.app-popup-close.inverse {
  background-image: url(./assets/common/close-inverse.png);
}

.app-frame-page {
  width: 100%;
  height: 100%;
}

@font-face {
  font-family: 'almmsh';
  src: url(./assets/common/alimamashuheiti.ttf);
}

@font-face {
  font-family: 'dinpro';
  src: url(./assets/common/dinpro.ttf);
}

@media screen and (orientation:landscape) {
  .app-page-container.pay {
    left: 50%;
    top: 50%;
    width: 100vh;
    width: calc(var(--vh, 1vh)*100);
    height: 100vw;
    transform: translate3d(-50%, -50%, 0) rotate(-90deg);
  }
}

@media screen and (orientation:portrait) {
  .app-page-container.tip-landscape {
    width: 100vh;
    width: calc(var(--vh, 1vh)*100);
    height: 100vw;
    left: 50%;
    top: 50%;
    transform: translate3d(-50%, -50%, 0) rotate(90deg);
  }
}