.app-tip-box {
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate3d(-50%, -50%, 0);
    background-color: rgba(0, 0, 0, 0.8);
    border-radius: 0.1rem;
    height: 0.48rem;
    padding: 0 0.2rem;
}

.app-tip-box.with-type {
    height: 0.94rem;
    padding-top: 0.16rem;
}

.app-tip-content {
    font-size: 0.14rem;
    line-height: 0.48rem;
    color: #ffffff;
    white-space: nowrap;
}

.app-tip-box.with-type .app-tip-content {
    line-height: 0.4rem;
}

.app-tip-icon {
    display: block;
    width: 0.32rem;
    height: 0.32rem;
    margin: 0 auto;
    background-size: 100% 100%;
}

.app-tip-icon.success {
    background-image: url(../../assets/common/success.png);
}

.app-game-tip-container {
    height: 0.8rem;
    background-image: linear-gradient(to bottom, #000000, rgba(0, 0, 0, 0.74) 47%, transparent);
    padding-top: 0.16rem;
}

.app-game-tip-content {
    font-size: 0.12rem;
    line-height: 0.17rem;
    color: rgba(255, 255, 255, 0.8);
    text-align: center;
}

.app-game-tip-content.time {
    line-height: 0.24rem;
}

.app-game-tip-value {
    color: #b7fe58;
    margin: 0 0.02rem;
}

.app-game-tip-icon {
    display: inline-block;
    vertical-align: top;
    width: 0.24rem;
    height: 0.24rem;
    background-image: url(../../assets/play/setting.png);
    background-size: 100% 100%;
    margin: 0 0.06rem;
}