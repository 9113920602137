.xbox-btn {
    position: absolute;
    background-size: 100% 100%;
    transform-origin: top left;
    pointer-events: auto;
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.xbox-btn-text {
    font-size: 0.12rem;
    line-height: 1.2;
    color: #ffffff;
    text-align: center;
}

.xbox-btn.btn-LT,
.xbox-btn.btn-RT {
    background-image: url(./asset/ltrt.png);
}

.xbox-btn.btn-LB,
.xbox-btn.btn-RB {
    background-image: url(./asset/lbrb.png);
}

.xbox-btn.btn-START {
    background-image: url(./asset/start.png);
}

.xbox-btn.btn-SELECT {
    background-image: url(./asset/select.png);
}

.xbox-btn.btn-LS,
.xbox-btn.btn-RS,
.xbox-btn.btn-A,
.xbox-btn.btn-B,
.xbox-btn.btn-X,
.xbox-btn.btn-Y {
    background-image: url(./asset/circle.png);
}

.xbox-btn.btn-LR {
    background-image: url(./asset/lr.png);
}

.xbox-btn.btn-DIRECTION {
    background-image: url(./asset/direction.png);
}

.xbox-btn.btn-RR {
    background-image: url(./asset/rr.png);
}