.custom-btn {
    position: absolute;
    width: 0.334rem;
    height: 0.334rem;
    background-image: url(../../assets/customPanel/btn.png);
    background-size: 100% 100%;
    /* padding-top: 0.13rem; */
    transform-origin: top left;
    pointer-events: auto;
}

.custom-btn.mouse {
    /* width: 0.6rem;
    height: 0.6rem; */
    padding: 0.05rem;
}

.custom-btn.joystick {
    width: 1.32rem;
    height: 1.32rem;
    background-image: url(../../assets/customPanel/wasd.png) !important;
}

.custom-btn.joystick.sxzy {
    background-image: url(../../assets/customPanel/sxzy.png) !important;
}

/* .custom-btn.macro {
    padding-top: 0.22rem;
} */

.custom-btn.game-mode:active,
.custom-btn.active {
    background-image: url(../../assets/customPanel/btn-active.png);
}

.custom-btn-name {
    font-size: 0.12rem;
    line-height: 0.334rem;
    color: #ffffff;
    white-space: nowrap;
    /* word-break: break-all; */
    text-align: center;
}

.custom-btn-name.multi-line {
    font-size: 0.13rem;
    line-height: 1;
    margin-top: 0.05rem;
}

.custom-btn-key {
    font-size: 0.12rem;
    line-height: 1;
    color: #b7fe58;
    white-space: nowrap;
    text-align: center;
}

.cp-btns-box-mouse-btn-icon {
    display: block;
    width: 100%;
    height: 100%;
    background-size: 100% 100%;
}

.cp-btns-box-mouse-btn-icon.mouse-left {
    background-image: url(../../assets/customPanel/mouse-left.png);
}

.cp-btns-box-mouse-btn-icon.mouse-right {
    background-image: url(../../assets/customPanel/mouse-right.png);
}

.cp-btns-box-mouse-btn-icon.mouse-center {
    background-image: url(../../assets/customPanel/mouse-center.png);
}

.cp-btns-box-mouse-btn-icon.mouse-up {
    background-image: url(../../assets/customPanel/mouse-up.png);
}

.cp-btns-box-mouse-btn-icon.mouse-down {
    background-image: url(../../assets/customPanel/mouse-down.png);
}