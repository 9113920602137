.cp-main-container {
    height: 100%;
    position: relative;
    z-index: 1;
    pointer-events: none;
}

.cp-top-bar-container {
    position: absolute;
    width: 100%;
    height: 1.3rem;
    left: 0;
    top: 0;
    background-image: linear-gradient(to bottom, #1b2025, rgba(27, 32, 37, 0.73), transparent);
    transition: top 400ms ease;
}

.cp-top-bar-container.away {
    top: -0.88rem;
}

.cp-top-bar-slide {
    display: block;
    width: 0.9rem;
    height: 0.24rem;
    background-image: url(../../assets/customPanel/put-away.png);
    background-size: 100% 100%;
    margin: 0.08rem auto 0;
}

.cp-top-macro-container {
    height: 0.44rem;
    padding: 0.12rem 0.16rem 0;
    display: flex;
    align-items: center;
    position: relative;
}

.cp-top-macro-back {
    width: 0.24rem;
    height: 0.24rem;
    background-image: url(../../assets/customPanel/back.png);
    background-size: 100% 100%;
}

.cp-top-macro-split {
    width: 1px;
    height: 0.12rem;
    background-color: rgba(255, 255, 255, 0.2);
    margin: 0 0.12rem;
}

.cp-top-macro-tip {
    font-size: 0.1rem;
    line-height: 1;
    color: #ffffff;
}

.cp-top-macro-save-btn {
    width: 0.8rem;
    height: 0.28rem;
    margin-left: auto;
    background-color: #b7fe58;
    border-radius: 0.08rem;
    font-size: 0.1rem;
    line-height: 0.28rem;
    color: #121310;
    text-align: center;
}

.cp-top-macro-save-btn.disabled {
    opacity: 0.4;
}

.cp-top-macro-content {
    position: absolute;
    height: 0.32rem;
    left: 50%;
    top: 0.12rem;
    transform: translateX(-50%);
    font-size: 0;
    white-space: nowrap;
}

.cp-top-macro-item,
.cp-top-macro-plus {
    display: inline-block;
    vertical-align: top;
}

.cp-top-macro-item {
    width: 0.32rem;
    height: 100%;
    border-radius: 0.16rem;
    background-color: rgba(255, 255, 255, 0.2);
    position: relative;
    padding-top: 0.09rem;
}

.cp-top-macro-plus {
    width: 0.24rem;
    height: 0.24rem;
    background-image: url(../../assets/customPanel/plus.png);
    background-size: 100% 100%;
    margin-top: 0.04rem;
}

.cp-top-macro-item-txt {
    font-size: 0.1rem;
    line-height: 0.14rem;
    color: rgba(255, 255, 255, 0.8);
    text-align: center;
}

.cp-top-macro-item-del {
    position: absolute;
    width: 0.1rem;
    height: 0.1rem;
    top: 0;
    right: 0;
    background-image: url(../../assets/customPanel/del.png);
    background-size: 100% 100%;
}

.cp-top-bar-btns {
    height: 0.52rem;
    padding: 0 0.16rem;
    display: flex;
    align-items: center;
}

.cp-top-bar-close {
    width: 0.24rem;
    height: 0.24rem;
    background-image: url(../../assets/customPanel/close.png);
    background-size: 100% 100%;
}

.cp-top-bar-split {
    width: 1px;
    height: 0.12rem;
    background-color: rgba(255, 255, 255, 0.2);
    margin: 0 0.12rem;
}

.cp-top-bar-split.closer {
    margin: 0;
}

.cp-top-bar-tip {
    font-size: 0.1rem;
    line-height: 1;
    color: #ffffff;
    margin-right: auto;
}

.cp-top-bar-tip .highlight {
    color: #bbfd39;
}

.cp-top-bar-btn {
    width: 0.8rem;
    height: 0.26rem;
    background-color: rgba(255, 255, 255, 0.2);
    border-radius: 0.08rem;
    font-size: 0.1rem;
    line-height: 0.26rem;
    color: #ffffff;
    text-align: center;
    margin-right: 0.1rem;
}

.cp-top-bar-btn.create::before {
    display: inline-block;
    vertical-align: top;
    content: '';
    width: 0.14rem;
    height: 0.14rem;
    background-image: url(../../assets/customPanel/add.png);
    background-size: 100% 100%;
    margin-top: 0.06rem;
    margin-right: 0.06rem;
}

.cp-top-bar-btn.default {
    width: 0.62rem;
    margin-left: 0.1rem;
}

.cp-top-bar-btn.save {
    width: 0.62rem;
    background-color: #b7fe58;
    color: #121310;
    margin-right: 0;
}

.cp-top-op-wrapper {
    height: 0.28rem;
    padding: 0 0.16rem;
}

.cp-top-op {
    height: 100%;
    padding: 0 0.12rem;
    display: flex;
    align-items: center;
    background-image: url(../../assets/customPanel/op-bg.png);
    background-size: 100% 100%;
}

.cp-top-op-tip {
    font-size: 0.1rem;
    line-height: 1;
    color: #ffffff;
    margin-left: 0.2rem;
}

.cp-top-op-tip:first-child {
    margin-left: 0;
}

.cp-top-op-number-wrapper {
    width: 0.92rem;
    height: 0.22rem;
    background-color: rgba(0, 0, 0, 0.4);
    border-radius: 0.06rem;
    position: relative;
    padding: 0.02rem 0.24rem;
    margin-left: 0.06rem;
}

.cp-top-op-number-btn {
    position: absolute;
    top: 0.02rem;
    width: 0.22rem;
    height: 0.18rem;
    background-size: 100% 100%;
}

.cp-top-op-number-btn.add {
    left: 0.02rem;
    background-image: url(../../assets/customPanel/add-btn.png);
}

.cp-top-op-number-btn.sub {
    right: 0.02rem;
    background-image: url(../../assets/customPanel/sub-btn.png);
}

.cp-top-op-number-txt {
    font-size: 0.1rem;
    line-height: 0.18rem;
    color: rgba(255, 255, 255, 0.8);
    text-align: center;
}

.cp-top-op-checkbox-wrapper {
    width: 0.52rem;
    height: 0.22rem;
    background-color: rgba(0, 0, 0, 0.4);
    border-radius: 0.06rem;
    font-size: 0.1rem;
    line-height: 0.22rem;
    color: rgba(255, 255, 255, 0.8);
    text-align: center;
    margin-left: 0.04rem;
}

.cp-top-op-checkbox {
    display: inline-block;
    vertical-align: top;
    width: 0.12rem;
    height: 0.12rem;
    margin-top: 0.05rem;
    margin-right: 0.04rem;
    background-image: url(../../assets/customPanel/check.png);
    background-size: 100% 100%;
}

.cp-top-op-checkbox.active {
    background-image: url(../../assets/customPanel/check-active.png);
}

.cp-top-op-input {
    width: 1rem;
    height: 0.2rem;
    margin-left: 0.08rem;
    background-color: rgba(0, 0, 0, 0.4);
    border-radius: 0.06rem;
    padding-left: 0.08rem;
    font-size: 0.1rem;
    line-height: 0.2rem;
    color: #ffffff;
}

.cp-top-op-btn {
    /* width: 0.56rem; */
    width: 0.36rem;
    height: 0.22rem;
    background-color: rgba(0, 0, 0, 0.4);
    border-radius: 0.06rem;
    font-size: 0.1rem;
    line-height: 0.22rem;
    color: rgba(255, 255, 255, 0.8);
    text-align: center;
    margin-left: auto;
}

/* .cp-top-op-btn.delete {
    width: 0.36rem;
    margin-left: 0.08rem;
} */

.cp-btns-box-wrapper {
    position: absolute;
    width: 100%;
    left: 0;
    bottom: -2.3rem;
}

.cp-btns-box-wrapper.with-ani {
    transition: bottom 400ms ease;
}

.cp-btns-box-wrapper.show {
    bottom: 0;
}

.cp-btns-box-header {
    height: 0.32rem;
}

.cp-btns-box-content {
    display: none;
    height: 1.96rem;
    padding: 0.1rem 0.17rem;
    background-color: #272e35;
}

.cp-btns-box-content.show {
    display: block;
    position: relative;
    z-index: 1;
}

.cp-btns-box-toggle {
    float: right;
    height: 100%;
    width: 0.32rem;
    padding: 0.11rem;
    background-color: #272e35;
    border-top-left-radius: 0.1rem;
    position: relative;
    z-index: 1;
}

.cp-btns-box-toggle::after {
    display: block;
    content: '';
    width: 100%;
    height: 100%;
    border-style: solid;
    border-width: 0 0 0.02rem 0.02rem;
    border-color: rgba(255, 255, 255, 0.4);
    transform: rotate(-45deg);
}

.cp-btns-box-types {
    width: 2.24rem;
    height: 100%;
    background-color: #373e44;
    border-top-right-radius: 0.1rem;
    position: relative;
    padding-right: 1.12rem;
    z-index: 1;
}

.cp-btns-box-types .type-1 {
    height: 100%;
    border-top-right-radius: 0.1rem;
    background-color: #272e35;
    color: #bbfd39;
}

.cp-btns-box-types .type-2 {
    position: absolute;
    width: 1.12rem;
    height: 100%;
    top: 0;
    right: 0;
    color: #ffffff;
}

.cp-btns-box-types .type-1,
.cp-btns-box-types .type-2 {
    font-size: 0.13rem;
    line-height: 0.32rem;
    text-align: center;
}

.cp-btns-box-types.index-1 {
    background-color: #272e35;
}

.cp-btns-box-types.index-1 .type-1 {
    background-color: #373e44;
    color: #ffffff;
}

.cp-btns-box-types.index-1 .type-2 {
    color: #bbfd39;
}

.cp-btns-box-mouse-grid {
    padding: 0.2rem 0.5rem 0;
    display: flex;
    justify-content: space-between;
}

.cp-btns-box-mouse-item {
    width: 0.68rem;
}

.cp-btns-box-mouse-btn {
    height: 0.68rem;
    background-image: url(../../assets/customPanel/btn.png);
    background-size: 100% 100%;
    padding: 0.14rem;
}

.cp-btns-box-mouse-btn.wasd {
    padding: 0;
    background-image: url(../../assets/customPanel/wasd.png);
}

.cp-btns-box-mouse-btn.sxzy {
    padding: 0;
    background-image: url(../../assets/customPanel/sxzy.png);
}

.cp-btns-box-mouse-btn-txt {
    font-size: 0.12rem;
    line-height: 0.16rem;
    color: #ffffff;
    margin-top: 0.11rem;
    text-align: center;
}

.cp-btns-box-keyboard-grid {
    height: 100%;
    display: grid;
    grid-template-columns: repeat(19, 1fr);
    grid-template-rows: repeat(6, 1fr);
    grid-column-gap: 0.02rem;
    grid-row-gap: 0.04rem;
}

.cp-btns-box-keyboard-item {
    background-color: #373e44;
    border-radius: 0.06rem;
    font-size: 0.1rem;
    line-height: 0.26rem;
    color: #ffffff;
    text-align: center;
}

.cp-btns-box-keyboard-item.id-33 {
    grid-area: 2 / 14 / 3 / 16
}

.cp-btns-box-keyboard-item.id-37 {
    grid-area: 2 / 19 / 4 / 20;
    line-height: 0.56rem;
}

.cp-btns-box-keyboard-item.id-38 {
    grid-area: 3 / 1 / 4 / 3
}

.cp-btns-box-keyboard-item.id-55 {
    grid-area: 4 / 1 / 5 / 3
}

.cp-btns-box-keyboard-item.id-67 {
    grid-area: 4 / 14 / 5 / 16
}

.cp-btns-box-keyboard-item.id-71 {
    grid-area: 4 / 19 / 7 / 20;
    line-height: 0.86rem;
}

.cp-btns-box-keyboard-item.id-72 {
    grid-area: 5 / 1 / 6 / 3
}

.cp-btns-box-keyboard-item.id-83 {
    grid-area: 5 / 13 / 6 / 15
}

.cp-btns-box-keyboard-item.id-88 {
    grid-area: 6 / 1 / 7 / 3
}

.cp-btns-box-keyboard-item.id-89 {
    grid-area: 6 / 3 / 7 / 5
}

.cp-btns-box-keyboard-item.id-90 {
    grid-area: 6 / 5 / 7 / 10
}

.cp-btns-box-keyboard-item.id-91 {
    grid-area: 6 / 10 / 7 / 12
}

.cp-btns-box-keyboard-item.id-92 {
    grid-area: 6 / 12 / 7 / 14
}