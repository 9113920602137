.app-dialog-box {
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate3d(-50%, -50%, 0);
    width: 2.8rem;
    padding: 0.24rem;
    background-color: #ffffff;
    border-radius: 0.16rem;
}

.app-dialog-title {
    font-size: 0.18rem;
    line-height: 0.26rem;
    color: #121310;
    font-weight: bold;
    text-align: center;
    white-space: normal;
}

.app-dialog-desc {
    margin-top: 0.08rem;
    font-size: 0.14rem;
    line-height: 0.2rem;
    color: #fb792e;
    text-align: center;
}

.app-dialog-btns-line {
    margin-top: 0.16rem;
    height: 0.48rem;
    display: flex;
    justify-content: space-between;
}

.app-dialog-btn {
    width: 1.08rem;
    height: 100%;
    background-color: #b7fe58;
    border-radius: 0.12rem;
    font-size: 0.14rem;
    line-height: 0.48rem;
    color: #121310;
    font-weight: bold;
    text-align: center;
}

.app-dialog-btn.cancel {
    background-color: #f6f8fc;
    color: #a1a7af;
}

.app-page-container.logout .app-dialog-desc {
    color: #6a7079;
}

.app-page-container.gameover .app-dialog-box {
    background-color: #272e35;
}

/*@media screen and (orientation:portrait) {
    .app-page-container.gameover .app-dialog-box {
        transform: translate3d(-50%, -50%, 0) rotate(90deg);
    }
}*/

.app-page-container.gameover .app-dialog-title {
    color: #ffffff;
}

.app-page-container.gameover .app-dialog-btn.cancel {
    background-color: #373e44;
    color: rgba(255, 255, 255, 0.4);
}