.package-page-container {
    min-height: 100%;
    background-image: url(./asset/bg.png);
    background-size: 100% auto;
    background-repeat: no-repeat;
    position: relative;
    padding-top: 73.333vw;
}

.package-countdown-wrap {
    position: absolute;
    width: 40.8vw;
    height: 6vw;
    padding-top: 0.8vw;
    padding-left: 4vw;
    font-size: 0;
    background-image: url(./asset/count-down.png);
    background-size: 100% 100%;
    left: 3.867vw;
    top: 37.333vw;
}

.package-countdown-block {
    display: inline-block;
    vertical-align: top;
    width: 5.333vw;
    height: 4.4vw;
    margin-right: 2.933vw;
    text-align: center;
    font-size: 3.2vw;
    line-height: 4.4vw;
    color: #000000;
    font-weight: bold;
}

.package-countdown-block:first-child {
    margin-right: 3.2vw;
}

.package-item {
    width: 95.467vw;
    height: 37.2vw;
    background-image: url(./asset/package-3.png);
    background-size: 100% 100%;
    margin: 0 auto 2.667vw;
    position: relative;
}

.package-item:nth-child(1) {
    background-image: url(./asset/package-1.png);
}

.package-item:nth-child(2) {
    background-image: url(./asset/package-2.png);
}

.package-item:nth-child(2)::after {
    position: absolute;
    content: '';
    width: 20vw;
    height: 6.667vw;
    background-image: url(./asset/corner.png);
    background-size: 100% 100%;
    right: 4vw;
    top: 0;
}

.package-name {
    height: 8.933vw;
    width: 63.333vw;
    font-size: 4.533vw;
    line-height: 8.633vw;
    color: #ffffff;
    font-weight: bold;
    text-align: center;
}

.package-content {
    margin-top: 3.333vw;
    width: 66.667vw;
    /* height: 10vw; */
    padding-left: 4.933vw;
    font-size: 4.8vw;
    line-height: 7vw;
    color: #000000;
    font-weight: bold;
    /* white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis; */
}

.package-activity-data {
    height: 7.2vw;
    padding-left: 8.733vw;
    margin-left: 4.933vw;
    margin-top: 1.5vw;
    font-size: 3.2vw;
    line-height: 7.2vw;
    color: #804523;
    font-weight: bold;
    background-image: url(./asset/count-bg.png);
    background-size: auto 100%;
    background-repeat: no-repeat;
    background-position: top left;
}

.highlight-data {
    color: #f4382b;
}

.package-price {
    position: absolute;
    height: 5.067vw;
    top: 12.4vw;
    right: 3.733vw;
    font-size: 6.667vw;
    line-height: 5.067vw;
    color: #f43529;
    font-weight: bold;
}

.package-item:nth-child(1) .package-price,
.package-item:nth-child(2) .package-price {
    color: #ffe553;
}

.package-yen {
    font-size: 3.2vw;
    margin-right: 0.667vw;
}

.package-original-price {
    position: absolute;
    height: 2.267vw;
    top: 18.933vw;
    right: 3.733vw;
    font-size: 2.4vw;
    line-height: 2.267vw;
    color: #ffffff;
    text-decoration: line-through;
}

.package-buy-btn {
    position: absolute;
    width: 21.867vw;
    height: 8.667vw;
    top: 23.733vw;
    right: 3.6vw;
    background-image: url(./asset/btn.png);
    background-size: 100% 100%;
}

.package-pay-container {
    position: absolute;
    width: 71.6vw;
    height: 96vw;
    background-image: url(./asset/pay.png);
    background-size: 100% 100%;
    left: 51.5%;
    top: 50%;
    transform: translate3d(-50%, -50%, 0);
    padding-top: 21.467vw;
}

.package-pay-close {
    position: absolute;
    width: 2.667vw;
    height: 2.667vw;
    background-image: url(./asset/close.png);
    background-size: 100% 100%;
    top: 17.067vw;
    right: 7.333vw;
}

.package-pay-price {
    height: 5.067vw;
    font-size: 6.667vw;
    line-height: 5.067vw;
    color: #f43529;
    font-weight: bold;
    text-align: center;
    padding-right: 3vw;
}

.package-pay-yen {
    font-size: 3.2vw;
    margin-right: 0.933vw;
}

.package-pay-line {
    height: 8vw;
    margin-top: 10.667vw;
    padding-right: 9.6vw;
    padding-top: 0.667vw;
}

.package-pay-line.wechat {
    margin-top: 4.667vw;
}

.package-pay-radio {
    float: right;
    height: 6.667vw;
    width: 6.667vw;
    background-image: url(./asset/radio.png);
    background-size: 100% 100%;
}

.package-pay-radio.active {
    background-image: url(./asset/radio-active.png);
}

.package-pay-btn-line {
    height: 10.8vw;
    margin-top: 9.333vw;
    font-size: 0;
    text-align: center;
    padding-right: 3vw;
}

.package-pay-btn {
    height: 100%;
    width: 35.2vw;
    background-image: url(./asset/pay-btn.png);
    background-size: 100% 100%;
}