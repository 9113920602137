.newyear-body {
    background-image: url(./asset/body-bg.png);
    background-size: 100% 100%;
    height: 155.467vw;
    position: relative;
}

.newyear-count-wrap {
    position: absolute;
    width: 51.333vw;
    height: 13.867vw;
    background-image: url(./asset/count.png);
    background-size: 100% 100%;
    top: 4.533vw;
    left: 9.067vw;
    padding-top: 2.933vw;
    font-size: 3.733vw;
    line-height: 7.867vw;
    color: #fff2e7;
    text-align: center;
}

.newyear-count {
    font-size: 4.267vw;
    color: #fafd13;
    margin: 0 1.867vw;
    vertical-align: -1px;
    font-weight: bold;
}

.newyear-get-count {
    position: absolute;
    width: 25.467vw;
    height: 10vw;
    background-image: url(./asset/get-count.png);
    background-size: 100% 100%;
    top: 7.333vw;
    right: 10.933vw;
}

.newyear-box-line {
    position: absolute;
    height: 0;
    width: 79.467vw;
    left: 9.067vw;
}

.newyear-box-line.line-3 {
    bottom: 32.667vw;
}

.newyear-box-line.line-2 {
    bottom: 68.8vw;
}

.newyear-box-line.line-1 {
    bottom: 104.933vw;
}

.newyear-box {
    position: absolute;
    width: 22vw;
    height: 25.2vw;
    background-image: url(./asset/box.png);
    background-size: 100% 100%;
    transform-origin: center 71%;
    bottom: 0;
}

.newyear-box.col-1 {
    left: 0;
}

.newyear-box.col-2 {
    left: 28.733vw;
}

.newyear-box.col-3 {
    right: 0;
}

.newyear-box.active {
    background-image: url(./asset/box-active.png);
    transform: scale3d(1.297, 1.28, 1);
}

.newyear-main-btn {
    position: absolute;
    width: 54.533vw;
    height: 15.2vw;
    background-image: url(./asset/main-btn.png);
    background-size: 100% 100%;
    bottom: 8vw;
    left: 22.734vw;
}