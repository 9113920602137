.newyear-footer {
    background-image: url(./asset/footer-bg.png);
    background-size: 100% 100%;
    height: 136vw;
    position: relative;
}

.newyear-prize-line {
    position: absolute;
    height: 26.533vw;
    width: 100%;
    left: 0;
    font-size: 0;
    white-space: nowrap;
    text-align: center;
    overflow: visible;
}

.newyear-prize-line.line-1 {
    bottom: 86.933vw;
}

.newyear-prize-line.line-2 {
    bottom: 53.067vw;
}

.newyear-prize-line.line-3 {
    bottom: 19.333vw;
}

.newyear-prize {
    display: inline-block;
    vertical-align: top;
    height: 100%;
    width: 29.867vw;
    margin-right: 1.467vw;
    background-size: 100% 100%;
    padding: 20vw 1.333vw 0;
}

.newyear-prize-text {
    height: 5.867vw;
    background-size: auto 100%;
    background-repeat: no-repeat;
    background-position: top right;
}

.newyear-prize-text.text-1 {
    background-image: url(./asset/prize-text-1.png);
    position: relative;
}

.newyear-prize-text.text-2 {
    background-image: url(./asset/prize-text-2.png);
}

.newyear-prize-text.text-3 {
    background-image: url(./asset/prize-text-3.png);
}

.newyear-prize-text.text-4 {
    background-image: url(./asset/prize-text-4.png);
}

.newyear-prize-text.text-5 {
    background-image: url(./asset/prize-text-5.png);
}

.newyear-prize-text.text-6 {
    background-image: url(./asset/prize-text-6.png);
}

.newyear-prize-text.text-7 {
    background-image: url(./asset/prize-text-7.png);
    background-position: top center;
}

.newyear-prize-text.text-8 {
    background-image: url(./asset/prize-text-8.png);
    background-position: top center;
}

.newyear-prize-text.text-9 {
    background-image: url(./asset/prize-text-9.png);
    background-position: top center;
}

.newyear-prize:last-child {
    margin-right: 0;
}

.newyear-prize.prize-1 {
    position: relative;
}

.newyear-prize.prize-1::before {
    content: '';
    position: absolute;
    width: 100%;
    height: 100%;
    left: 0;
    top: 0;
    transform-origin: center top;
    transform: scale3d(1.098, 1.055, 1);
    background-image: url(./asset/prize-1.png);
    background-size: 100% 100%;
}

.newyear-prize.prize-2 {
    background-image: url(./asset/prize-2.png);
}

.newyear-prize.prize-3 {
    background-image: url(./asset/prize-3.png);
}

.newyear-prize.prize-4 {
    background-image: url(./asset/prize-4.png);
}