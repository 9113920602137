.newyear-header {
    background-image: url(./asset/header-bg.png);
    background-size: 100% 100%;
    height: 155.467vw;
    padding-top: 101.467vw;
    position: relative;
}

.newyear-header-time {
    height: 6.4vw;
    text-align: center;
    font-size: 3.2vw;
    line-height: 2;
    color: #ffffff;
}

.newyear-rule-btn {
    position: absolute;
    width: 10.933vw;
    height: 17.733vw;
    background-image: url(./asset/rule.png);
    background-size: 100% 100%;
    right: 0;
    bottom: 48vw;
}

.barrage-container {
    width: 88.667vw;
    height: 38.4vw;
    background-image: url(./asset/barrage-bg.png);
    background-size: 100% 100%;
    margin: 6.533vw auto 0;
    padding: 5.333vw 3.2vw 0;
}

.barrage-box {
    height: 29.333vw;
    border: 2px solid #000000;
    overflow: hidden;
    padding-top: 1.067vw;
}

.barrage-line {
    font-size: 0;
    white-space: nowrap;
    width: 1528vw;
    height: 7.733vw;
    margin-bottom: 1.333vw;
    animation-name: barrage-go;
    animation-timing-function: linear;
    animation-iteration-count: infinite;
}

.barrage-line:last-child {
    margin-bottom: 0;
}

.barrage-item {
    display: inline-block;
    vertical-align: top;
    height: 100%;
    width: 68.667vw;
    background-size: 100% 100%;
    margin-left: 7.733vw;
    padding-left: 20vw;
    font-size: 2.933vw;
    line-height: 6.667vw;
    color: #373939;
}

.barrage-item:first-child {
    margin-left: 0;
}

.barrage-item.color-1 {
    background-image: url(./asset/barrage-1.png);
}

.barrage-item.color-2 {
    background-image: url(./asset/barrage-2.png);
}

.barrage-item.color-3 {
    background-image: url(./asset/barrage-3.png);
}

.barrage-nickname {
    max-width: 23.333vw;
    overflow: hidden;
    text-overflow: ellipsis;
}

@keyframes barrage-go {
    from {
        transform: translateX(0);
    }

    to {
        transform: translateX(-50%);
    }
}